import React from "react";
import Input from "../../components/preview-input/input";
import SizeSelection from "./size-selection";
import FasciaReplacementSelection from "./fascia-replacement-selection";
import usePreviewStore from "../../store/preview-store";
import { useLocation } from "react-router-dom";
import Painted from "./painted";

const Fascia = () => {
  const { previewData, setFormData } = usePreviewStore();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const handleInputChange = (field) => (e) => {
    setFormData({
      ...previewData,
      [field]: e.target.value,
    });
  };
  return (
    <div className="k-px-2 k-border-solid k-border k-border-black k-mt-0">
      <FasciaReplacementSelection />
      <SizeSelection />

      <div className="k-d-flex k-flex-wrap k-align-content-between k-grid-cols-1 k-mt-4 k-gap-10">
    <div className="k-d-flex k-flex-wrap k-gap-5" >
    <h4 className="k-font-light k-m-0 k-pt-1">
          Type:
        </h4>
          {/* <Input
            value={previewData.fascia_type}
            onChange={handleInputChange("fascia_type")}
          /> */}
          <select value={previewData?.fascia_type?.toLowerCase()} onChange={handleInputChange('fascia_type')} disabled={isDisabled} style={{width:"200px",height:"30px",borderRadius:"5px"}} id="colorSelect" name="fascia_type">
            <option value="pine">Pine</option>
            <option value="cedar">Cedar</option>
        </select>
    </div> 
       {/* <div className="k-d-flex">
       <h4 className="k-font-light k-flex-basis-auto k-d-flex k-m-0 k-w-40 k-pl-12 k-pt-1">
          Painted / Metal Wrapped / Other
        </h4>
        <div className="k-flex-basis-auto k-d-flex k-m-0 k-w-40  k-font-light k-mt-0 k-pl-8">
          <Input
            value={previewData.fascia_other}
            onChange={handleInputChange("fascia_other")}
          />
        </div>
       </div> */}
       <Painted/>
      </div>

      <div className="k-d-flex k-flex-wrap  k-grid-cols-1 k-mt-4 k-mb-4">
        <h4 className="k-font-light k-flex-basis-auto k-d-flex k-m-0 k-w-30">
          Total FT. of new wrap:{" "}
        </h4>
        <div className="k-flex-basis-auto k-d-flex k-m-0 k-w-40  k-font-light k-mt-0">
          <Input
          type="number"
            value={previewData.fascia_wrap}
            onChange={handleInputChange("fascia_wrap")}
          />
        </div>
        <h4 className="k-font-light k-flex-basis-auto k-d-flex k-m-0  k-pl-12 k-sm-pl-0">
          (* We will put up primed, Cust. to Paint.)
        </h4>
      </div>
    </div>
  );
};

export default Fascia;
