export const orderValues = {
  customer: "",
  city: "",
  state: "",
  job: "",
  gutterProfile: "K-Style",
  gutterOpeningWidthValue:'',
  leafFilterProfile: [],
  versa_max_quantity:"",
  measurements: [
    {
      label: "Left Side of Home",
      firstStory: ["", ""],
      secondStory: ["", ""],
      thirdStory: ["", ""],
    },
    {
      label: "Right Side of Home",
      firstStory: ["", ""],
      secondStory: ["", ""],
      thirdStory: ["", ""],
    },
    {
      label: "Front of Home",
      firstStory: ["", ""],
      secondStory: ["", ""],
      thirdStory: ["", ""],
    },
    {
      label: "Rear of Home",
      firstStory: ["", ""],
      secondStory: ["", ""],
      thirdStory: ["", ""],
    },
    {
      label: "Garage",
      firstStory: ["", ""],
      secondStory: ["", ""],
      thirdStory: ["", ""],
    },
    {
      label: "Other",
      firstStory: ["", ""],
      secondStory: ["", ""],
      thirdStory: ["", ""],
    },
    {
      label: "Total",
      firstStory: ["", ""],
      secondStory: ["", ""],
      thirdStory: ["", ""],
    },
    {
      label: "R & RR Total",
      firstStory: ["", ""],
      secondStory: ["", ""],
      thirdStory: ["", ""],
    },
  ],
  color: "White",
  leafFilterColor: "White",
  existingGutterColor: "",
  gutterOpeningWidth: [],
  totalFootage: "",
  partial: "Yes",
  csr: false,
  csr_value: '',
  insideCorners: 0,
  outsideCorners: 0,
  existingProtection: "Yes",
  existingProtectionType: "screen",
  existingProtectionTypeValue:0,
  mountingType: "Hidden Hangers",
  // mountingTypeValue: "",
  mountingLength: "",
  roof: "Yes",
  // roofValue:"",
  roofColor: "red",
  // roofMaterialValue: "",
  roofType: "Flat Roof",
  roofTypeValue: "",
  extensionsNeeded: "Yes",
  extensionFootage:'',
  groundSpoutNeeded:'Yes',
  units: 0,
  sizes: [
    { label: "1", values: ["", "", "", ""] },
    { label: "2", values: ["", "", "", ""] },
    { label: "3", values: ["", "", "", ""] },
    { label: "4", values: ["", "", "", ""] },
    { label: "5", values: ["", "", "", ""] },
    { label: "6", values: ["", "", "", ""] },
  ],
  AElbows: 0,
  BElbows: 0,
  spoutSaverQuantity: "",
  ladders: "8",
  laddersValue: "",
  walkboards: "Yes",
  obstruction: "",
  note: "",
  files:[],
  hasSheet: false,
  retailPrice:{
    firstStoryTotal1: '',
    firstStoryTotal2: '',
    secondStoryTotal1: '',
    secondStoryTotal2: '',
    thirdStoryTotal1: '',
    thirdStoryTotal2: '',
    firstStoryRAndRTotal1: '',
    firstStoryRAndRTotal2: '',
    secondStoryRAndRTotal1: '',
    secondStoryRAndRTotal2: '',
    thirdStoryRAndRTotal1: '',
    thirdStoryRAndRTotal2: '',
    cleanSealReinforce: '',
    ofInsideCorners1: '',
    ofInsideCorners2: '',
    type: '',
    footage: '',
    units: '',
    ofAElbows1: '',
    ofAElbows2: '',
  },
  constPrice:{
    firstStoryTotal1: '',
    firstStoryTotal2: '',
    secondStoryTotal1: '',
    secondStoryTotal2: '',
    thirdStoryTotal1: '',
    thirdStoryTotal2: '',
    firstStoryRAndRTotal1: '',
    firstStoryRAndRTotal2: '',
    secondStoryRAndRTotal1: '',
    secondStoryRAndRTotal2: '',
    thirdStoryRAndRTotal1: '',
    thirdStoryRAndRTotal2: '',
    cleanSealReinforce: '',
    ofInsideCorners1: '',
    ofInsideCorners2: '',
    type: '',
    footage: '',
    units: '',
    ofAElbows1: '',
    ofAElbows2: '',
  }
};
