import React, { useState } from 'react'
import Input from '../../components/preview-input/input'
import kStyle from '../../assets/images/k-style.png'
import halfRound from '../../assets/images/half-round.png'
import fascia from '../../assets/images/fascia.png'
import useOrderStore from '../../store/order-store'
import { useLocation } from 'react-router-dom'
const OrderProfile = () => {
  const { orderData,  setFormData } = useOrderStore();
  const selectedProfile = orderData.gutterProfile;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const handleInputChange = (value) => {
    if(isDisabled){
      return
    }
    setFormData({ 
      ...orderData,
      gutterProfile: value
     });
  };
  return (
   <div className='k-d-grid k-grid-cols-3 k-grid-sm-cols-1 k-text-center k-sm-text-left k-gap-20 k-sm-gap-2 k-pt-4 k-pb-1 k-px-2'>
   <div className='k-display-flex k-flex-wrap k-justify-content-between k-align-items-center k-gap-2'>
      <h2 className='k-m-0 k-sm-w-100'>Gutter Profile:</h2>
  
     <div className='k-display-flex k-justify-content-center k-sm-justify-content-start k-align-items-center k-gap-3 k-sm-gap-1' onClick={() => handleInputChange("K-Style")}>
     <input
       type="radio"
       name="gutterProfile"
       value="K-Style"
       disabled={isDisabled}
       checked={selectedProfile === "K-Style"}
       onChange={() => handleInputChange("K-Style")}
       className="k-mr-2"
     />
         <h2 className='k-sm-fs-15'>K-Style</h2>
       <div className='k-pos-relative k-top-1 k-left-10'>
         <img className="k-sm-w-img" alt='k-style' src={kStyle} />
       </div>
     </div>
   </div>

   <div
     className='k-display-flex k-justify-content-center k-sm-justify-content-start  k-align-items-center k-gap-3 k-sm-gap-1'
     onClick={() => handleInputChange("Half-Round")}
   >
     <input
       type="radio"
       name="gutterProfile"
       value="Half-Round"
       disabled={isDisabled}
       checked={selectedProfile === "Half-Round"}
       onChange={() => handleInputChange("Half-Round")}
       className="k-mr-2"
     />
     <h2 className='k-m-0 k-sm-fs-15'>Half-Round</h2>
     <div className='k-pos-relative k-top-1 k-left-10 k-left-sm-5'>
       <img className="k-sm-w-img" alt='halfRound' src={halfRound} />
     </div>
   </div>

   <div
     className='k-display-flex k-justify-content-center k-sm-justify-content-start k-align-items-center k-gap-3 k-sm-gap-1'
     onClick={() => handleInputChange("Fascia")}
   >
     <input
       type="radio"
       name="gutterProfile"
       value="Fascia"
       disabled={isDisabled}
       checked={selectedProfile === "Fascia"}
       onChange={() => handleInputChange("Fascia")}
       className="k-mr-2"
     />
     <h2 className='k-m-0 k-sm-fs-15'>Fascia</h2>
     <div className='k-pos-relative k-top-1 k-left-10'>
       <img className="k-sm-w-img" alt='fascia' src={fascia} />
     </div>
   </div>
 </div>
  )
}

export default OrderProfile