import { forwardRef } from 'react';
import myLogo from '../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx,logoUpdate, ...other }, ref) => {
  const navigate=useNavigate()
  const logo = (
    <div style={{display:'flex' , alignItems:"center"}}
    onClick={()=>navigate('/')}
    ref={ref}
    {...other}
  >
    <img alt='logo' src={logoUpdate || myLogo} className="k-max-h-full k-max-w-full k-cursor-pointer k-sm-w-logo" />
  </div>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    // <Link href="/" className="k-link">
        logo
    // </Link>
  );
});

export default Logo;