export const previewValues={
    customer: '',
    city: '',
    st: '',
    job: '',
    measurements: Array(7).fill({
      firstStory: "",
      secondStory: "",
      thirdStory: ""
    }),
    roof_type:'Asphalt Shingle',
    color:'WHITE',
    gutter_type:'FS',
    gutter_size:'4',
    total_gutter_footage:0,
    downspout:'2x3',
    downspout_color:'WHITE',
    downspout_footage:'',
    aelbows:'',
    belbows:'',
    roundElbows:'',
    offsetElbows:'',
    gutter_downspout_elbows:'',
    outside:'',
    inside:'',
    rights:'',
    lefts:'',
    savers:'',
    straps:'',
    wedges:'',
    partial_install:'Yes',
    fascia_replacement:'Full',
    fascia_total_footage:0,
    fascia_size:'1x4',
    fascia_other_size:'',
    fascia_type:'pine',
    fascia_other:'Painted',
    metal_wrapped_color:'WHITE',
    fascia_other_value:'',
    fascia_wrap:'',
    soffit_replacement:'Full',
    soffit_total_footage:0,
    soffit_other:'Wood',
    soffit_other_value:'',
    soffit_color:'WHITE',
    ribbedRoofQuantity:0,
    porch_ceiling: Array(5).fill({
      location: "",
      length: "",
      width: "",
      squareFeet: ""
    }),
    files:[],
    images:[],
    costPrice: {
      inCLudeInPrimaryColors:false,
      firstStoryTotal: '',
      secondStoryTotal: '',
      thirldSotryTotal: '',
      totalGutterFootage: "",
      downspoutFootage: "",
      aElbows: "",
      bElbows: "",
      totalGutterDownspoutElbows: "",
      ofWedges: "",
      fasciaTotalFootage: "",
      totalFTOfNewWrap: "",
      soffitTotalFootage: "",
    },
    retailPrice: {
      inCLudeInPrimaryColors:false,
      firstStoryTotal: '',
      secondStoryTotal: '',
      thirldSotryTotal: '',
      totalGutterFootage: "",
      downspoutFootage: "",
      aElbows: "",
      bElbows: "",
      totalGutterDownspoutElbows: "",
      ofWedges: "",
      fasciaTotalFootage: "",
      totalFTOfNewWrap: "",
      soffitTotalFootage: "",
    },
}