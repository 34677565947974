import React from 'react'
import Input from '../../components/preview-input/input'
import usePreviewStore from '../../store/preview-store';

const PorchCeiling = () => {
    const { previewData, setFormData } = usePreviewStore();

    const handleInputChange = (index, field) => (e) => {
      const value = field === "length" || field === "width" ? parseFloat(e.target.value) || 0 : e.target.value;
  
      const newRows = previewData.porch_ceiling.map((row, i) => {
        if (i === index) {
          const updatedRow = { ...row, [field]: value };
          
          // Update squareFeet if length or width is changed
          if (field === "length" || field === "width") {
            updatedRow.squareFeet = (updatedRow.length || 0) * (updatedRow.width || 0);
          }
          return updatedRow;
        }
        return row;
      });
  
      setFormData({...previewData, porch_ceiling: newRows});
    };
  
  return (
    <div
    className="k-d-flex k-flex-col k-mt-2 k-sm-w-40"
    style={{ padding: "8px", alignItems: "center" }}
  >
    <div
      className="k-d-flex k-grid-cols-1 k-mt-4 k-justify-content-center"
      style={{ justifyContent: "center" }}
    >
      <h3
        className="k-flex-basis-auto k-d-flex k-m-0 k-w-35"
        style={{ textDecoration: "underline", textAlign: "center" }}
      >
        Porch Ceiling
      </h3>
    </div>
    <div
      className="k-d-flex k-grid-cols-4 k-pb-0 "
      style={{ justifyContent: "center", width: "100%" }}
    >
      <h4 className='k-sm-fs' style={{ textAlign: "center", width: "25%" }}>Location</h4>
      <h4 className='k-sm-fs' style={{ textAlign: "center", width: "25%" }}>Length x</h4>
      <h4 className='k-sm-fs' style={{ textAlign: "center", width: "25%" }}>
        Width  ={" "}
      </h4>
      <h4 className='k-sm-fs' style={{ textAlign: "center", width: "25%" }}>Sq. Ft.</h4>
    </div>

    {[...Array(5)].map((_, index) => (
      <div
        key={index}
        className="k-d-flex k-grid-cols-4"
        style={{
          justifyContent: "center",
          width: "100%",
          borderTop: "2px solid black",
          borderBottom: index == 4 ? "2px solid black" : "",
          borderRight: "2px solid black",
          borderLeft: "2px solid black",
        }}
      >
        <div
          className="k-p-4 "
          style={{ textAlign: "center", width: "25%" }}
        >
          <div
            className="k-m-0 k-font-light"
            style={{ position: "relative", top: "18px" }}
          >
            <Input
              value={previewData.porch_ceiling[index].location}
          onChange={handleInputChange(index, "location")}
            />
          </div>
        </div>
        <div
          className="k-p-4 k-border-solid k-border k-border-b-0 k-border-t-0 k-border-r-0"
          style={{ textAlign: "center", width: "25%" }}
        >
          <div
            className="k-m-0 k-font-light"
            style={{ position: "relative", top: "18px" }}
          >
            <Input
            type='number'
              value={previewData.porch_ceiling[index].length}
          onChange={handleInputChange(index, "length")}
            />
          </div>
        </div>
        <div
          className="k-p-4 k-border-solid k-border k-border-b-0 k-border-t-0 k-border-r-0"
          style={{ textAlign: "center", width: "25%" }}
        >
          <div
            className="k-m-0 k-font-light"
            style={{ position: "relative", top: "18px" }}
          >
            <Input
            type='number'
              value={previewData.porch_ceiling[index].width}
          onChange={handleInputChange(index, "width")}
            />
          </div>
        </div>
        <div
          className="k-p-4 k-border-solid k-border k-border-b-0 k-border-t-0 k-border-r-0"
          style={{ textAlign: "center", width: "25%" }}
        >
          <div
            className="k-m-0 k-font-light"
            style={{ position: "relative", top: "18px" }}
          >
            <Input
            disable
            type='number'
              value={previewData.porch_ceiling[index].squareFeet}
          onChange={handleInputChange(index, "squareFeet")}
            />
          </div>
        </div>
      </div>
    ))}
  </div>
  )
}

export default PorchCeiling