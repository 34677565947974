import React from 'react'
import Input from '../../components/preview-input/input'
import ColorSelection from './color-selection'
import ModularVersaSelection from './modular-versa-selection'
import GutterOpeningWidthSelection from './gutter-opening-width-selection'
import PartialSelection from './partial-selection'
import ExistingProtectionSelection from './existing-protection-selection'
import FastenerSelection from './fastener-selection'
import FastenerTypeSelection from './fastener-type-selection'
import YesNoColorSelection from './yes-no-color-selection'
import RoofingTypeSelection from './roofing-type-selection'
import RoofTypeSelection from './roof-type-selection'
import useOrderStore from '../../store/order-store';
import { useLocation } from 'react-router-dom'

const FilterColors = () => {
  const { orderData, setFormData } = useOrderStore();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const handleInputChange = (field) => (e) => {
    setFormData({
      ...orderData,
      [field]: e.target.value
    });
  };

  return (
    <div className="k-pl-6 k-sm-pl-0 k-pb-4 k-pt-2">
   
 <ColorSelection/>

    <h4 style={{borderBottom:"2px solid black"  , width:"fit-content"}}>
      LeafFilter Color
    </h4>

   <ModularVersaSelection/>

    <div className="k-display-flex k-flex-wrap k-gap-2 k-mt-4">
      <h4 className="k-m-0 k-font-light k-pt-2">Existing Gutter Color:</h4>
      <div className="k-pos-relative k-top-1">
        <Input value={orderData.existingGutterColor} onChange={handleInputChange('existingGutterColor')}/>
      </div>
    </div>


  <GutterOpeningWidthSelection/>
   

    <div className="k-display-flex k-flex-wrap k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2">Total Footage</h4>
      <div className="k-pos-relative k-top-1 k-ml-4" style={{width:"100px"}}>
        <Input disable value={orderData.totalFootage} onChange={handleInputChange('totalFootage')}/>
      </div>
    <PartialSelection/>
   {
    orderData.leafFilterProfile.includes('Versa Max') &&
   <>
   <h4 className="k-m-0 k-font-light k-pt-2 k-pl-4">Versa Max Quantity</h4>
      <div className="k-pos-relative k-top-1 k-ml-4" style={{width:"100px"}}>
        <Input  value={orderData.versa_max_quantity} onChange={handleInputChange('versa_max_quantity')}/>
      </div>
   </>
   }
    </div>



    <div className="k-display-flex k-flex-wrap k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2">Clean, Seal,  Rainforce</h4>
      <div className="k-pos-relative k-top-1 k-ml-4 k-ml-sm-0 k-sm-pl-0 k-sm-w-100" style={{width:"100px"}}>
        <Input type='number' value={orderData.csr_value} onChange={handleInputChange('csr_value')}/>
      </div>
      <h4 className="k-m-0 k-font-light k-pt-2 k-ml-4">
      ft.   (Check if CSR Only)
      </h4>
      <input
            type="checkbox"
            name="leafFilterProfile"
            value="Core"
            disabled={isDisabled}
            checked={orderData.csr}
            onChange={(e) => {
              setFormData({
                ...orderData,
                csr: e.target.checked
              });
            }}
            className="k-ml-2 k-mt-2"
          />
    </div>


   


    <div className="k-display-flex k-flex-wrap k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2"># of Inside Corners</h4>
      <div className="k-pos-relative k-top-1 k-ml-4 k-ml-sm-0 k-sm-w-100" style={{width:"100px"}}>
        <Input type="number" value={orderData.insideCorners} onChange={handleInputChange('insideCorners')}/>
      </div>
      <h4 className="k-m-0 k-font-light k-pt-2 k-ml-4 k-ml-sm-0">
      # of Outside Corners
      </h4>
      <div className="k-pos-relative k-top-1 k-ml-4 k-ml-sm-0 k-sm-w-100" style={{width:"100px"}}>
        <Input type="number" value={orderData.outsideCorners} onChange={handleInputChange('outsideCorners')}/>
      </div>
    </div>



    <div className="k-display-flex k-flex-wrap k-mt-1">
     <ExistingProtectionSelection/>
    {orderData.existingProtection=='Yes' &&  <div className="k-pos-relative k-top-1 k-ml-4 k-ml-sm-0 k-sm-w-100" style={{width:"130px"}}>
        {/* <Input value={orderData.existingProtectionType} onChange={handleInputChange('existingProtectionType')}/> */}
        <select disabled={isDisabled} value={orderData?.existingProtectionType?.toLowerCase()} onChange={handleInputChange('existingProtectionType')} style={{width:"100%",height:"30px",borderRadius:"5px"}} id="colorSelect" name="color">
            <option value="screen">Screen</option>
            <option value="gutter helmet">Gutter Helmet</option>
            <option value="other">Other</option>
        </select>
      </div>}
      <div>
     {orderData.existingProtection=='Yes' &&  orderData?.existingProtectionType.toLowerCase()=='other' && <div className="k-pos-relative k-mt-3 k-ml-4 k-ml-sm-0 k-sm-w-100" style={{width:"100px"}}>
        <Input type="number" value={orderData.existingProtectionTypeValue} onChange={handleInputChange('existingProtectionTypeValue')}/>
      </div>}
      </div>
    </div>

    <FastenerSelection/>

   {/* <FastenerTypeSelection/> */}


  <YesNoColorSelection/>



    <RoofingTypeSelection/>

    {/* <RoofTypeSelection/> */}


  





  </div>
  )
}

export default FilterColors