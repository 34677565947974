import axios from 'axios';
import { HOST_API } from '../config-global';
// config
// import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    me: '/auth',
    login: '/auth/v1/login',
    register: '/auth/register',
  },
  role: {
    list: '/admin/roles',
  },
  salesForce:{
  login:"/salesforce/v1/login"
  },
  leads: {
    get_all_leads: '/leads/v1/?today=true',
    get_lead_detail: (id) => `/leads/v1/detail/${id}`,
    get_sheet_detail: (id) => `/sheets/v1/gutter/${id}`,
    get_order_detail: (id) => `/sheets/v1/order/${id}`,
    post_lead_preview: '/sheets/v1/gutter',
    post_order: '/sheets/v1/order',
  },
  
};
