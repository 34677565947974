import React from 'react'
import usePreviewStore from '../../store/preview-store';
import Input from '../../components/preview-input/input';

const TotalFootageDetails = () => {
    const { previewData, setFormData } = usePreviewStore();

    const handleInputChange = (field) => (e) => {
      setFormData({
        ...previewData,
        [field]: e.target.value,
      });
    };
  
  return (
    <div> 
         <div className="k-d-grid k-grid-cols-2 k-grid-sm-cols-1 k-mt-3 k-pr-4">
    <div className="k-d-grid k-grid-cols-2 k-grid-sm-cols-1">
      <h4 className="k-m-0 k-font-light k-pt-2"># of Milters:(outside)</h4>
      <div className="k-pos-relative k-top-1 k-right-3 k-sm-right-0">
        <Input type='number' value={previewData.outside} onChange={handleInputChange('outside')} />
      </div>
    </div>
    <div className="k-d-grid k-grid-cols-2 k-grid-sm-cols-1">
      <h4 className="k-m-0 k-font-light k-pt-2 k-pt-sm-4">(Inside)</h4>
      <div className="k-pos-relative k-top-1" style={{width:"100%"}}>
        <Input type='number' value={previewData.inside} onChange={handleInputChange('inside')} />
      </div>
    </div>
  </div>
  <div className="k-d-grid k-grid-cols-2 k-grid-sm-cols-1 k-mt-2 k-pr-4">
    <div className="k-d-grid k-grid-cols-2 k-grid-sm-cols-1">
      <h4 className="k-m-0 k-font-light k-pt-2"># End Caps: (Rights)</h4>
      <div className="k-pos-relative k-top-1 k-right-3 k-sm-right-0">
        <Input type='number' value={previewData.rights} onChange={handleInputChange('rights')} />
      </div>
    </div>
    <div className="k-d-grid k-grid-cols-2 k-grid-sm-cols-1">
      <h4 className="k-m-0 k-font-light k-pt-2 k-pt-sm-4">(Lefts)</h4>
      <div className="k-pos-relative k-top-1" style={{width:"100%"}}>
        <Input type='number' value={previewData.lefts} onChange={handleInputChange('lefts')} />
      </div>
    </div>
  </div>
  <div className="k-d-grid k-grid-cols-2 k-grid-sm-cols-1 k-mt-2 k-pr-4">
    <div className="k-d-grid k-grid-cols-2 k-grid-sm-cols-1">
      <h4 className="k-m-0 k-font-light k-pt-2"># of Spout Savers:</h4>
      <div className="k-pos-relative k-top-1 k-right-3 k-sm-right-0">
        <Input type='number' value={previewData.savers} onChange={handleInputChange('savers')} />
      </div>
    </div>
    <div className="k-d-grid k-grid-cols-2 k-grid-sm-cols-1">
      <h4 className="k-m-0 k-font-light k-pt-2 k-pt-sm-4" style={{width:"150px"}}># of Roof Straps:</h4>
      <div className="k-pos-relative k-top-1">
        <Input type='number' value={previewData.straps} onChange={handleInputChange('straps')} />
      </div>
    </div>
  </div>
  </div>
  )
}

export default TotalFootageDetails