import React from 'react'

const TableComponent = ({label1,label2,label3}) => {
  return (
    <div>
<div style={{ display: "flex" }}>
            <div
              style={{
                height: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>{label1}</p>
            </div>
            <div
              style={{
                height: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
            <p>${label2 ? String(label2).replace(/,/g, ",$") : ""}</p>

            </div>
            <div
              style={{
                height: "40px",
                border: "1px solid black",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>{label3}</p>
            </div>
          </div>

    </div>
  )
}

export default TableComponent