export const getDataForSf=(sfData)=>{
  let fourPrimaryColors = ["WHITE", "CLAY", "LINEN", "COCOA BROWN"];

  const inCLudeInPrimaryColors = fourPrimaryColors.includes(
    sfData?.color?.toUpperCase() || "WHITE"
  );
  ///retail price 
  const firstStoryTotal1Retail = (sfData.measurements[6]?.firstStory[0] || 0) * 38;
  const firstStoryTotal2Retail = (sfData.measurements[6]?.firstStory[1] || 0) * 39;
  const secondStoryTotal1Retail = (sfData.measurements[6]?.secondStory[0] || 0) * 40;
  const secondStoryTotal2Retail = (sfData.measurements[6]?.secondStory[1] || 0) * 41;
  const thirdStoryTotal1Retail = (sfData.measurements[6]?.thirdStory[0] || 0) * 42;
  const thirdStoryTotal2Retail = (sfData.measurements[6]?.thirdStory[1] || 0) * 43;

  const firstStoryRRTotal1Retail = (sfData.measurements[7]?.firstStory[0] || 0) * 4;
  const firstStoryRRTotal2Retail = (sfData.measurements[7]?.firstStory[1] || 0) * 4;
  const secondStoryRRTotal1Retail = (sfData.measurements[7]?.secondStory[0] || 0) * 4;
  const secondStoryRRTotal2Retail = (sfData.measurements[7]?.secondStory[1] || 0) * 4;
  const thirdStoryRRTotal1Retail = (sfData.measurements[7]?.thirdStory[0] || 0) * 4;
  const thirdStoryRRTotal2Retail = (sfData.measurements[7]?.thirdStory[1] || 0) * 4;

  const cleanSealReinforceRetail=Number(sfData.csrValue) * 7
  const ofInsideCornersRetail=Number(sfData.insideCorners) * 25
  const ofOutsideCornersRetail=Number(sfData.outsideCorners) * 25
  const typeRetail=Number(sfData.existingProtectionTypeValue) * 3
  const footageRetail=Number(sfData.extensionFootage) * 25
  const unitsRetail=Number(sfData.units) * 50
  const ofAElbowsRetail=Number(sfData.AElbows) * (inCLudeInPrimaryColors ?  21 : 22)
  const ofBElbowsRetail=Number(sfData.BElbows) * (inCLudeInPrimaryColors ?  21 : 22)
  const sizesRetail = sfData.sizes.map(item => ({
    label: item.label,
    values: item.values.map(value => (parseInt(value) * (inCLudeInPrimaryColors ?  21 : 22)))
  }));
  //cost price

  const firstStoryTotal1Cost = (sfData.measurements[6]?.firstStory[0] || 0) * 16;
  const firstStoryTotal2Cost = (sfData.measurements[6]?.firstStory[1] || 0) * 16;
  const secondStoryTotal1Cost = (sfData.measurements[6]?.secondStory[0] || 0) * 16;
  const secondStoryTotal2Cost = (sfData.measurements[6]?.secondStory[1] || 0) * 16;
  const thirdStoryTotal1Cost = (sfData.measurements[6]?.thirdStory[0] || 0) * 16;
  const thirdStoryTotal2Cost = (sfData.measurements[6]?.thirdStory[1] || 0) * 16;


  const firstStoryRRTotal1Cost = (sfData.measurements[7]?.firstStory[0] || 0) * 2;
  const firstStoryRRTotal2Cost = (sfData.measurements[7]?.firstStory[1] || 0) * 2;
  const secondStoryRRTotal1Cost = (sfData.measurements[7]?.secondStory[0] || 0) * 2;
  const secondStoryRRTotal2Cost = (sfData.measurements[7]?.secondStory[1] || 0) * 2;
  const thirdStoryRRTotal1Cost = (sfData.measurements[7]?.thirdStory[0] || 0) * 2;
  const thirdStoryRRTotal2Cost = (sfData.measurements[7]?.thirdStory[1] || 0) * 2;

  const cleanSealReinforceCost=Number(sfData.csrValue) * 3.5
  const ofInsideCornersCost=Number(sfData.insideCorners) * 12.5
  const ofOutsideCornersCost=Number(sfData.outsideCorners) * 12.5
  const typeCost=Number(sfData.existingProtectionTypeValue) * 1.75
  const footageCost=Number(sfData.extensionFootage) *  (inCLudeInPrimaryColors ?  10.5 : 11)
  const unitsCost=Number(sfData.units) * 25
  const ofAElbowsCost=Number(sfData.AElbows) * (inCLudeInPrimaryColors ?  10.5 : 11)
  const ofBElbowsCost=Number(sfData.BElbows) * (inCLudeInPrimaryColors ?  10.5 : 11)


  const sizesCost = sfData.sizes.map(item => ({
    label: item.label,
    values: item.values.map(value => (parseInt(value) * 10.5))
  }));

  const objectForSf={
    ...sfData,
    retailPrice:{
        firstStoryTotal1:firstStoryTotal1Retail,
        firstStoryTotal2: firstStoryTotal2Retail,
        secondStoryTotal1: secondStoryTotal1Retail,
        secondStoryTotal2: secondStoryTotal2Retail,
        thirdStoryTotal1: thirdStoryTotal1Retail,
        thirdStoryTotal2: thirdStoryTotal2Retail,
        firstStoryRAndRTotal1: firstStoryRRTotal1Retail,
        firstStoryRAndRTotal2: firstStoryRRTotal2Retail,
        secondStoryRAndRTotal1: secondStoryRRTotal1Retail,
        secondStoryRAndRTotal2: secondStoryRRTotal2Retail,
        thirdStoryRAndRTotal1: thirdStoryRRTotal1Retail,
        thirdStoryRAndRTotal2: thirdStoryRRTotal2Retail,
        cleanSealReinforce: cleanSealReinforceRetail,
        ofInsideCorners: ofInsideCornersRetail,
        ofOutSideCorners: ofOutsideCornersRetail,
        type: typeRetail,
        footage: footageRetail,
        units: unitsRetail,
        ofAElbows: ofAElbowsRetail,
        ofBElbows: ofBElbowsRetail,
        sizes:sizesRetail,
        isColor:inCLudeInPrimaryColors
      },
      costPrice:{
        firstStoryTotal1: firstStoryTotal1Cost,
        firstStoryTotal2: firstStoryTotal2Cost,
        secondStoryTotal1: secondStoryTotal1Cost,
        secondStoryTotal2: secondStoryTotal2Cost,
        thirdStoryTotal1: thirdStoryTotal1Cost,
        thirdStoryTotal2: thirdStoryTotal2Cost,
        firstStoryRAndRTotal1: firstStoryRRTotal1Cost,
        firstStoryRAndRTotal2: firstStoryRRTotal2Cost,
        secondStoryRAndRTotal1: secondStoryRRTotal1Cost,
        secondStoryRAndRTotal2: secondStoryRRTotal2Cost,
        thirdStoryRAndRTotal1: thirdStoryRRTotal1Cost,
        thirdStoryRAndRTotal2: thirdStoryRRTotal2Cost,
        cleanSealReinforce: cleanSealReinforceCost,
        ofInsideCorners: ofInsideCornersCost,
        ofOutSideCorners: ofOutsideCornersCost,
        type: typeCost,
        footage: footageCost,
        units: unitsCost,
        ofAElbows: ofAElbowsCost,
        ofBElbows: ofBElbowsCost,
        sizes:sizesCost,
        isColor:inCLudeInPrimaryColors
      }
  }
  return objectForSf
}