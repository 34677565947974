import { useState } from "react";
import Input from "../../components/preview-input/input";
import useOrderStore from "../../store/order-store";
import { useLocation } from "react-router-dom";

function RoofingTypeSelection() {
  const { orderData,  setFormData } = useOrderStore();
  const selectedRoofing = orderData.roofType;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const handleInputChange = (value) => {
    if(isDisabled){
      return
    }
    setFormData({ 
      ...orderData,
      roofType: value
     });
  };
  const handleInputChange2 = (field) => (e) => {
    setFormData({
      ...orderData,
      [field]: e.target.value
    });
  };
  return (
    <div className="k-display-flex k-flex-wrap k-mt-1">
      <div>
      <h4 className="k-m-0 k-font-light k-pt-2 k-sm-w-100" style={{width:"120px"}}>Roof Types:</h4>
      </div>

      {/* <div className="k-pos-relative k-top-1" style={{ width: "100px" }}>
        <Input  value={orderData.roofTypeValue} onChange={handleInputChange2('roofTypeValue')}/>
      </div> */}

      <div className="k-m-0 k-font-light k-pt-2 k-ml-4 k-ml-sm-0 k-display-flex" style={{flexWrap:"wrap"}}>
        <label className='k-ml-sm-0' style={{margin:"0px 10px 10px"}}>
          <input
          disabled={isDisabled}
            type="radio"
            name="roofing"
            value="Asphalt Shingle"
            checked={selectedRoofing === "Asphalt Shingle"}
            onChange={() => handleInputChange("Asphalt Shingle")}
            style={{marginRight:"10px"}}
          />
          Asphalt Shingle
        </label>
        <label className='k-ml-sm-0' style={{margin:"0px 10px 10px"}}>
          <input
          disabled={isDisabled}
            type="radio"
            name="roofing"
            value="Metal"
            checked={selectedRoofing === "Metal"}
            onChange={() => handleInputChange("Metal")}
            style={{marginRight:"10px"}}
          />
          Metal
        </label>
        <label className='k-ml-sm-0' style={{margin:"0px 10px 10px"}}>

          <input
          disabled={isDisabled}
            type="radio"
            name="roofing"
            value="Tile"
            checked={selectedRoofing === "Tile"}
            onChange={() => handleInputChange("Tile")}
            style={{marginRight:"10px"}}
          />
          Tile
        </label>
        <label className='k-ml-sm-0' style={{margin:"0px 10px 10px"}}>
          <input
          disabled={isDisabled}
            type="radio"
            name="roofing"
            value="Slate"
            checked={selectedRoofing === "Slate"}
            onChange={() => handleInputChange("Slate")}
            style={{marginRight:"10px"}}
          />
          Slate
        </label>
        <label className='k-ml-sm-0' style={{margin:"0px 10px 10px"}}>
          <input
          disabled={isDisabled}
            type="radio"
            name="roofing"
            value="Flat Roof"
            checked={selectedRoofing === "Flat Roof"}
            onChange={() => handleInputChange("Flat Roof")}
            style={{marginRight:"10px"}}
          />
          Flat Roof
        </label>
        
        <label className='k-ml-sm-0' style={{margin:"0px 10px 10px"}}>
          <input
          disabled={isDisabled}
            type="radio"
            name="roofing"
            value="Wood Shake"
            checked={selectedRoofing === "Wood Shake"}
            onChange={() => handleInputChange("Wood Shake")}
            style={{marginRight:"10px"}}
          />
          Wood Shake
        </label>
        <label className='k-ml-sm-0' style={{margin:"0px 10px 10px"}}>
          <input
          disabled={isDisabled}
            type="radio"
            name="roofing"
            value="Other"
            checked={selectedRoofing === "Other"}
            onChange={() => handleInputChange("Other")}
            style={{marginRight:"10px"}}
          />
          Other
          
        </label>
        {orderData.roofType=='Other' &&  <div className="k-pos-relative k-top-1 k-ml-2" style={{ width: "100px"}}>
        <Input value={orderData.roofTypeValue} onChange={handleInputChange2('roofTypeValue')}/>
      </div>}
      </div>
    </div>
  );
}

export default RoofingTypeSelection;
