import { useState } from "react";
import Input from "../../components/preview-input/input";
import useOrderStore from "../../store/order-store";
import { useLocation } from "react-router-dom";

function ExtensionsNeededSelection() {
  const { orderData,  setFormData } = useOrderStore();
  const extensionsNeeded = orderData.extensionsNeeded;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const handleInputChange = (value) => {
    if(isDisabled){
      return
    }
    setFormData({ 
      ...orderData,
      extensionsNeeded: value
     });
  };
  const handleInputChange2 = (field) => (e) => {
    setFormData({
      ...orderData,
      [field]: e.target.value
    });
  };
  return (
    <div className="k-display-flex k-flex-wrap k-mt-1 " >
      <h4 className="k-m-0 k-font-light k-pt-2 k-d-flex-sm-cloumn">
        Extensions Needed:&nbsp;
        <label className="k-ml-sm-0" style={{ marginRight: "8px" }}>
          <input
          disabled={isDisabled}
            type="radio"
            name="extensionsNeeded"
            value="Yes"
            checked={extensionsNeeded === "Yes"}
            onChange={() => handleInputChange("Yes")}
            style={{ marginRight: "10px" }}
            className="k-ml-sm-0"
          />
          Yes
        </label>
        <label className="k-ml-sm-0" style={{ marginLeft: "8px" }}>
          <input
          disabled={isDisabled}
            type="radio"
            name="extensionsNeeded"
            value="No"
            checked={extensionsNeeded === "No"}
            onChange={() => handleInputChange("No")}
            style={{ marginRight: "10px" }}
            className="k-ml-sm-0"
          />
          No
        </label>
        &nbsp; Footage:
      </h4>

      <div className="k-pos-relative k-top-1 k-ml-4 k-ml-sm-0 k-sm-w-90" style={{ width: "100px" }}>
        <Input type="number" value={orderData.extensionFootage} onChange={handleInputChange2('extensionFootage')}/>
      </div>

      <h4 className="k-m-0 k-font-light k-pt-2 k-ml-3">
        FT.
      </h4>
    </div>
  );
}

export default ExtensionsNeededSelection;
