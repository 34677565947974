import { useState } from "react";
import Input from "../../components/preview-input/input";
import useOrderStore from "../../store/order-store";
import { useLocation } from "react-router-dom";

function GutterOpeningWidthSelection() {
  const { orderData, setFormData } = useOrderStore();
  const selectedWidths = orderData.gutterOpeningWidth || []; // Ensure it defaults to an array
  const gutterOpeningWidthValue = orderData.gutterOpeningWidthValue;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get("hasSheet");
  const isDisabled = hasSheet === "true";

  const handleCheckboxChange = (value) => {
    if (isDisabled) {
      return;
    }

    const updatedWidths = selectedWidths.includes(value)
      ? selectedWidths.filter((item) => item !== value) // Remove if already selected
      : [...selectedWidths, value]; // Add if not selected

    setFormData({
      ...orderData,
      gutterOpeningWidth: updatedWidths,
    });
  };

  const handleInputChange = (field) => (e) => {
    setFormData({
      ...orderData,
      [field]: e.target.value,
    });
  };

  return (
    <div className="k-display-flex k-flex-wrap k-mt-1">
      <h4 className="k-m-0 k-font-light k-pt-2">Gutter Opening Width:</h4>

      <div className="k-display-flex k-flex-wrap k-gap-3 k-ml-2">
        {["4", "4.5", "5", "5.5", "6", "7", "8"].map((width) => (
          <label key={width} className="k-m-0 k-font-light k-pt-2">
            <input
              disabled={isDisabled}
              style={{ marginRight: "10px" }}
              type="checkbox"
              value={width}
              checked={selectedWidths.includes(width)}
              onChange={() => handleCheckboxChange(width)}
            />
            {width}
          </label>
        ))}
      </div>

      <div
        className="k-pos-relative k-top-2 k-ml-4 k-w-sm-100"
        style={{ width: "100px" }}
      >
        <Input
          type="number"
          value={gutterOpeningWidthValue}
          onChange={handleInputChange("gutterOpeningWidthValue")}
        />
      </div>
    </div>
  );
}

export default GutterOpeningWidthSelection;
