import { Button } from "@progress/kendo-react-buttons";
import React from "react";

const DocumentUpload = () => {
  return (
    <div
      className="k-rounded-lg k-elevation-1 k-p-4 k-mb-4 k-cursor-pointer"
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <h2 className="k-font-light k-m-0 k-pt-4 k-text-uppercase">
        Document Upload:
      </h2>
      <div className="k-display-flex k-justify-content-end k-gap-2 k-mt-2 k-md-flex-column">
      <Button
                primary={true}
                className="k-button-blue"
                size="large"
              >
                Lead Pictures
              </Button>
              <Button
                primary={true}
                className="k-button-blue"
                size="large"
              >
                Lead Measure Sheet
              </Button>
      </div>
    </div>
  );
};

export default DocumentUpload;
