import { useState } from "react";
import useOrderStore from '../../store/order-store'
import { useLocation } from "react-router-dom";

function ColorSelection() {
  const { orderData,  setFormData } = useOrderStore();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const selectedColor = orderData.color;
  const handleInputChange = (value) => {
    if(isDisabled){
      return
    }
    setFormData({ 
      ...orderData,
      color: value
     });
  };
  return (
    <div className="k-d-grid k-grid-cols-5 k-grid-sm-cols-1 k-mt-3 k-pr-4">
      <h4 className="k-m-0 k-font-light k-pt-2">Color:</h4>

      <label className="k-m-0 k-font-light k-pt-2">
        <input
        disabled={isDisabled}
        style={{marginRight:"10px"}}
          type="radio"
          name="color"
          value="White"
          checked={selectedColor === "White"}
          onChange={() => handleInputChange("White")}
        />
        White
      </label>

      <label className="k-m-0 k-font-light k-pt-2">
        <input
        disabled={isDisabled}
        style={{marginRight:"10px"}}
          type="radio"
          name="color"
          value="Beige"
          checked={selectedColor === "Beige"}
          onChange={() => handleInputChange("Beige")}
        />
        <span>
          Beige
        </span>
      </label>

      <label className="k-m-0 k-font-light k-pt-2">
        <input
        disabled={isDisabled}
        style={{marginRight:"10px"}}
          type="radio"
          name="color"
          value="Clay"
          checked={selectedColor === "Clay"}
          onChange={() => handleInputChange("Clay")}
        />
        Clay
      </label>

      <label className="k-m-0 k-font-light k-pt-2">
        <input
        disabled={isDisabled}
        style={{marginRight:"10px"}}
          type="radio"
          name="color"
          value="Gray"
          checked={selectedColor === "Gray"}
          onChange={() => handleInputChange("Gray")}
        />
        Gray
      </label>
    </div>
  );
}

export default ColorSelection;
