import React from "react";
import { TextArea } from "@progress/kendo-react-inputs";
import styles from "./SingleLead.module.scss";

const SingleLeadDetailCard = () => {
  return (
    <div
      className="k-rounded-lg k-elevation-1 k-p-4 k-mb-4 k-cursor-pointer"
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <h2 className="k-font-normal k-m-0 k-pt-4 k-text-uppercase">Explain Other:</h2>
      <div className="k-mt-2">
        <TextArea
          className={`${styles.detail_textArea}`}
          rows={4}
          placeholder="This field is only required if you select 'Demo' from Result and 'Other' from Reason. Limit 100 Characters"
        />
      </div>
    </div>
  );
};

export default SingleLeadDetailCard;
