import React from 'react'
import { useRouter } from '../../pages/routes/hooks/use-router'

const EntryCard2 = ({item,handleClick}) => {
  return (
    <div onClick={()=>handleClick(item)} className='k-text-center'>
    <div className='k-py-8 k-px-16 k-elevation-1 k-rounded-lg k-cursor-pointer' style={{color:"#FFFFFF"}}>
        <img height={75} alt='img' src={item.image}/>
    </div>
  <h2 className='k-font-bold k-mt-5'>{item.title}</h2>
  </div>
  )
}

export default EntryCard2