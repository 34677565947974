import { colorList } from "../../utils/formats";
let fourPrimaryColors = ["WHITE", "CLAY", "LINEN", "COCOA BROWN"];
export const getDataForSalesFor = (previewData, leadId) => {
  //caluclations for sales force
  console.log(previewData?.color?.toUpperCase(), "let go");
  const inCLudeInPrimaryColors = fourPrimaryColors.includes(
    previewData?.color?.toUpperCase() || "WHITE"
  );
  //Cost Price
  let firstStoryTotalCost =
    previewData.measurements
      .slice(0, 6)
      .reduce((sum, s) => sum + (s.firstStory || 0), 0) *
    (inCLudeInPrimaryColors ? 10.5 : 11);
  let secondStoryTotalCost =
    previewData.measurements
      .slice(0, 6)
      .reduce((sum, s) => sum + (s.secondStory || 0), 0) *
    (inCLudeInPrimaryColors ? 10.5 : 11);
  let thirldSotryTotalCost =
    previewData.measurements
      .slice(0, 6)
      .reduce((sum, s) => sum + (s.thirdStory || 0), 0) *
    (inCLudeInPrimaryColors ? 10.5 : 11);

  let fasciaTotalFootageCost =
    (firstStoryTotalCost + secondStoryTotalCost + thirldSotryTotalCost) * 12.5;
  let sofitTotalFootageCost =
    (firstStoryTotalCost + secondStoryTotalCost + thirldSotryTotalCost) *
    (inCLudeInPrimaryColors ? 11.5 : 10.5);

  let totalGutterFootageCost =
    (firstStoryTotalCost + secondStoryTotalCost + thirldSotryTotalCost) *
    (inCLudeInPrimaryColors ? 10.5 : 11);

  let totalFtOfNewWrapCost = Number(previewData.fascia_wrap) * 9;
let downSpoutFootageCost=Number(previewData.downspout_footage) * (inCLudeInPrimaryColors ? 10.5 : 11);
let aElbowCost=Number(previewData.aelbows) * (inCLudeInPrimaryColors ? 10.5 : 11);
let bElbowCost=Number(previewData.belbows) * (inCLudeInPrimaryColors ? 10.5 : 11);
let ofWedgesCost=Number(previewData.wedges) * 1.75

let totalGutterDownspoutElbowsCost =
downSpoutFootageCost +
aElbowCost +
bElbowCost +
Number(previewData.offsetElbows) +
Number(previewData.roundElbows) +
totalGutterFootageCost



let porchCeilingCost = previewData?.porch_ceiling.map(
  (item) => ({
    ...item,
    squareFeet: item.squareFeet * (inCLudeInPrimaryColors ? 7.5 : 8.5),
  })
);






  //Retail Price

  let firstStoryTotalRetail =
    previewData.measurements
      .slice(0, 6)
      .reduce((sum, s) => sum + (s.firstStory || 0), 0) *
    (inCLudeInPrimaryColors ? 21 : 22);
  let secondStoryTotalRetail =
    previewData.measurements
      .slice(0, 6)
      .reduce((sum, s) => sum + (s.secondStory || 0), 0) *
    (inCLudeInPrimaryColors ? 21 : 22);
  let thirldSotryTotalRetail =
    previewData.measurements
      .slice(0, 6)
      .reduce((sum, s) => sum + (s.thirdStory || 0), 0) *
    (inCLudeInPrimaryColors ? 21 : 22);

  let fasciaTotalFootageRetail =
    (firstStoryTotalRetail + secondStoryTotalRetail + thirldSotryTotalRetail) *
    25;

  let soffitTotalFootageRetail =
    (firstStoryTotalRetail + secondStoryTotalRetail + thirldSotryTotalRetail) *
    25;
  let totalGutterFootageRetail =
    (firstStoryTotalRetail + secondStoryTotalRetail + thirldSotryTotalRetail) *
    (inCLudeInPrimaryColors ? 21 : 22);

  let totalFtOfNewWrapRetail = Number(previewData.fascia_wrap) * 24;
  let downSpoutFootageRetail=Number(previewData.downspout_footage) * (inCLudeInPrimaryColors ? 21 : 22)
  let aElbowRetail=Number(previewData.aelbows) * (inCLudeInPrimaryColors ? 21 : 22);
let bElbowRetail=Number(previewData.belbows) * (inCLudeInPrimaryColors ? 21 : 22);
let ofWedgesRetail=Number(previewData.wedges) * 3.5
let totalGutterDownspoutElbowsRetail =
downSpoutFootageRetail +
aElbowRetail +
bElbowRetail +
Number(previewData.offsetElbows) +
Number(previewData.roundElbows) +
totalGutterFootageRetail

let porchCeilingRetail = previewData?.porch_ceiling.map(
  (item) => ({
    ...item,
    squareFeet: item.squareFeet * 15,
  })
);



  const subdata = {
    customer: previewData.customer || "",
    city: previewData.city || "",
    street: previewData.st || "",
    leadId: Number(leadId),
    // leadId: Math.random().toString(),
    measurements: previewData.measurements,
    ribbedRoofQuantity:
      previewData.roof_type == "Ribbed Roof"
        ? Number(previewData.ribbedRoofQuantity)
        : 0,
    roof: {
      sumOfAllFootage:
        Number(previewData?.total_gutter_footage) +
        Number(previewData?.aelbows) +
        Number(previewData?.belbows) +
        Number(previewData?.roundElbows) +
        Number(previewData?.offsetElbows) +
        Number(previewData.downspout_footage),
      roofType: previewData.roof_type || "",
      gutterType:
        previewData.gutter_type == "FS"
          ? "Fascia Style"
          : previewData.gutter_type == "HR"
          ? "Half Round"
          : previewData.gutter_type,
      gutterColor: colorList.includes(
        previewData?.color?.toUpperCase() || "WHITE"
      )
        ? previewData?.color || "WHITE"
        : "WHITE",
      gutterSize: Number(previewData.gutter_size) || 0,
      totalGutterFootage: Number(previewData.total_gutter_footage) || 0,
      downSpout: previewData.downspout || "",
      downSpoutColor: colorList.includes(
        previewData?.downspout_color?.toUpperCase() || "WHITE"
      )
        ? previewData?.downspout_color || "WHITE"
        : "WHITE",

      downSpoutFootage: {
        ofAElbows: Number(previewData.aelbows) || 0,
        ofBElbows: Number(previewData.belbows) || 0,
        ofRoundElbows: Number(previewData.roundElbows) || 0,
        ofOffsetElbows: Number(previewData.offsetElbows) || 0,
        totalFootage: Number(previewData.downspout_footage) || 0,
      },
    },

    misc: {
      ofMitersOutside: Number(previewData.outside) || 0,
      ofMitersInside: Number(previewData.inside) || 0,
      ofEndcapsRights: Number(previewData.rights) || 0,
      ofEndcapsLefts: Number(previewData.lefts) || 0,
      ofSproutSavers: Number(previewData.savers) || 0,
      ofRoofStraps: Number(previewData.straps) || 0,
      ofwedges: Number(previewData.wedges) || 0,
    },
    partialInstall: previewData.partial_install == "Yes" ? true : false,

    fasciaReplacement: {
      type: previewData.fascia_replacement || "",
      totalFootage: Number(previewData.fascia_total_footage) || 0,
      size:
        previewData.fascia_size === "Other"
          ? previewData.fascia_other_size || ""
          : previewData.fascia_size || "",
      productType:
        previewData.fascia_type == "Other"
          ? previewData.fascia_other_value
          : previewData.fascia_type,
      productDetail:
        previewData.fascia_other == "Other"
          ? previewData.fascia_other_value
          : previewData.fascia_other,
      totalFtOfNewWrap: previewData.fascia_wrap || "",
      wrapColor: "White",
    },

    soffitReplacement: {
      replacementType: previewData.soffit_replacement || "",
      soffitType:
        previewData.soffit_other == "Other"
          ? previewData.soffit_other_value
          : previewData.soffit_other,
      soffitColor: previewData.soffit_color || "",
      totalFootage: Number(previewData.soffit_total_footage) || 0,
    },
    porchCeiling: previewData?.porch_ceiling,
    metalWrappedColor:
      previewData.fascia_other == "Metal Wrapped"
        ? colorList.includes(
            previewData?.metal_wrapped_color?.toUpperCase() || "WHITE"
          )
          ? previewData?.metal_wrapped_color || "WHITE"
          : "WHITE"
        : "",

    costPrice: {
      inCLudeInPrimaryColors: inCLudeInPrimaryColors,
      firstStoryTotal: firstStoryTotalCost,
      secondStoryTotal: secondStoryTotalCost,
      thirldSotryTotal: thirldSotryTotalCost,
      totalGutterFootage: totalGutterFootageCost,
      downspoutFootage: downSpoutFootageCost,
      aElbows: aElbowCost,
      bElbows: bElbowCost,
      totalGutterDownspoutElbows: totalGutterDownspoutElbowsCost,
      ofWedges: ofWedgesCost,
      fasciaTotalFootage: fasciaTotalFootageCost,
      totalFTOfNewWrap: totalFtOfNewWrapCost,
      soffitTotalFootage: sofitTotalFootageCost,
      porchCeiling:porchCeilingCost
    },
    retailPrice: {
      inCLudeInPrimaryColors: inCLudeInPrimaryColors,
      firstStoryTotal: firstStoryTotalRetail,
      secondStoryTotal: secondStoryTotalRetail,
      thirldSotryTotal: thirldSotryTotalRetail,
      totalGutterFootage: totalGutterFootageRetail,
      downspoutFootage: downSpoutFootageRetail,
      aElbows: aElbowRetail,
      bElbows: bElbowRetail,
      totalGutterDownspoutElbows: totalGutterDownspoutElbowsRetail,
      ofWedges: ofWedgesRetail,
      fasciaTotalFootage: fasciaTotalFootageRetail,
      totalFTOfNewWrap: totalFtOfNewWrapRetail,
      soffitTotalFootage: soffitTotalFootageRetail,
      porchCeiling:porchCeilingRetail
    },
  };
  return subdata;
};
