import React from "react";
import Input from "../../components/preview-input/input";
import useOrderStore from "../../store/order-store";

const Story = () => {
  const { orderData, setFormData } = useOrderStore();

  // const handleInputChange = (index, field, arrayIndex) => (e) => {
  //   const newMeasurements = orderData.measurements.map((measurement, i) =>
  //     i === index
  //       ? {
  //           ...measurement,
  //           [field]: measurement[field].map((value, idx) =>
  //             idx === arrayIndex ? e.target.value : value
  //           ),
  //         }
  //       : measurement
  //   );
  //   setFormData({...orderData, measurements: newMeasurements });
  // };

  const handleInputChange = (index, field, arrayIndex) => (e) => {
    // Update the measurements array with the new value
    const newMeasurements = orderData.measurements.map((measurement, i) =>
      i === index
        ? {
            ...measurement,
            [field]: measurement[field].map((value, idx) =>
              idx === arrayIndex ? e.target.value : value
            ),
          }
        : measurement
    );
  
    // Initialize totalValues object to store totals for each story
    const totalValues = {
      firstStory: [0, 0], // total for [0] and [1] indices
      secondStory: [0, 0],
      thirdStory: [0, 0]
    };
  
    // Calculate totals for firstStory, secondStory, and thirdStory for both indices [0] and [1]
    ['firstStory', 'secondStory', 'thirdStory'].forEach((story) => {
      [0, 1].forEach((idx) => {
        const totalForIndex = newMeasurements.reduce((total, measurement, i) => {
          if (i !== newMeasurements.length - 2) {  // Exclude the "Total" measurement
            total += parseFloat(measurement[story][idx]) || 0; // Handle NaN if value is empty
          }
          return total;
        }, 0);
        totalValues[story][idx] = totalForIndex; // Store total for each index (0 and 1)
      });
    });
  
    // Update the "Total" section with the new calculated totals for all stories and indices
    newMeasurements[newMeasurements.length - 2].firstStory[0] = totalValues.firstStory[0].toString();
    newMeasurements[newMeasurements.length - 2].secondStory[0] = totalValues.secondStory[0].toString();
    newMeasurements[newMeasurements.length - 2].thirdStory[0] = totalValues.thirdStory[0].toString();
  
    // Store the totals for the second index (index 1) for each story as well
    newMeasurements[newMeasurements.length - 2].firstStory[1] = totalValues.firstStory[1].toString();
    newMeasurements[newMeasurements.length - 2].secondStory[1] = totalValues.secondStory[1].toString();
    newMeasurements[newMeasurements.length - 2].thirdStory[1] = totalValues.thirdStory[1].toString();
    const grandTotal = Object.values(totalValues).reduce((storySum, storyTotals) => {
      return storySum + storyTotals.reduce((indexSum, indexTotal) => indexSum + indexTotal, 0);
    }, 0);
    // Set the updated data to the state
    setFormData({ ...orderData, measurements: newMeasurements,totalFootage:grandTotal});
  };
  

  const handleUpdateTotal=()=>{
    let newMeasurements= orderData.measurements

    const totalValues = {
      firstStory: [0, 0], // total for [0] and [1] indices
      secondStory: [0, 0],
      thirdStory: [0, 0]
    };
    ['firstStory', 'secondStory', 'thirdStory'].forEach((story) => {
      [0, 1].forEach((idx) => {
        const totalForIndex = newMeasurements.reduce((total, measurement, i) => {
          if (i !== newMeasurements.length - 2) {  // Exclude the "Total" measurement
            total += parseFloat(measurement[story][idx]) || 0; // Handle NaN if value is empty
          }
          return total;
        }, 0);
        totalValues[story][idx] = totalForIndex; // Store total for each index (0 and 1)
      });
    });
      // Update the "Total" section with the new calculated totals for all stories and indices
      newMeasurements[newMeasurements.length - 2].firstStory[0] = totalValues.firstStory[0].toString() || 0;
      newMeasurements[newMeasurements.length - 2].secondStory[0] = totalValues.secondStory[0].toString() || 0;
      newMeasurements[newMeasurements.length - 2].thirdStory[0] = totalValues.thirdStory[0].toString() || 0;
    
      // Store the totals for the second index (index 1) for each story as well
      newMeasurements[newMeasurements.length - 2].firstStory[1] = totalValues.firstStory[1].toString() || 0;
      newMeasurements[newMeasurements.length - 2].secondStory[1] = totalValues.secondStory[1].toString() || 0;
      newMeasurements[newMeasurements.length - 2].thirdStory[1] = totalValues.thirdStory[1].toString() || 0;
      const grandTotal = Object.values(totalValues).reduce((storySum, storyTotals) => {
        return storySum + storyTotals.reduce((indexSum, indexTotal) => indexSum + indexTotal, 0);
      }, 0);
      // Set the updated data to the state
      setFormData({ ...orderData, measurements: newMeasurements,totalFootage:grandTotal});
  }
  
  

  return (
    <div className="k-border-r-sm" style={{ borderRight: "1px solid black" }}>
      {/* Header Row */}
      <div className="grid-tem-column-sm"
        style={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr 1fr 1fr",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <h4 className="k-sm-display-none" style={{ fontWeight: "bold" }}></h4>
        <h4 className="k-sm-m-10">0-12 ft.</h4>
        <h4 className="k-sm-m-10">12.1 ft.- 24ft.</h4>
        <h4 className="k-sm-m-10">Over 24ft.</h4>
      </div>

      <div className="grid-tem-column-sm"
        style={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr 1fr 1fr",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <h4 style={{ textAlign: "left" }}>Story:</h4>
        <h4 className="k-sm-m-10">1st Story</h4>
        <h4 className="k-sm-m-10">2nd Story</h4>
        <h4 className="k-sm-m-10">3rd Story</h4>
      </div>

      {/* Rows for each side */}
      {orderData?.measurements && orderData?.measurements?.map((measurement, index) => (
        <div className="grid-tem-column-sm"
          key={index}
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 1fr 1fr",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h4 onClick={()=>{
            if(index==orderData.measurements.length-2){
              handleUpdateTotal()
            }
          }} style={{ fontWeight:index==orderData.measurements.length-2 ? "bolder" :  "normal", textAlign: "left",cursor:index==orderData.measurements.length-2 ? "pointer" :  "", }}>
            {measurement.label}
          </h4>
          {["firstStory", "secondStory", "thirdStory"].map((field, fieldIndex) => (
            <div
              key={fieldIndex}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {[0, 1].map((arrayIndex) => (
                <div
                  key={arrayIndex}
                  style={{
                    background:index==orderData.measurements.length-2 ? "gainsboro" : "",
                    border: "1px solid black",
                    padding: "5px",
                    marginRight: "5px",
                    textAlign: "center",
                    width: "30px",
                  }}
                >
                  <div
                    className="k-pos-relative stories-input"
                    style={{ top: "6px", right: "5px", width: "36px" }}
                  >
                    <Input
                     disable={index==orderData.measurements.length-2}
                      type="number"
                      value={measurement[field][arrayIndex] || ""}
                      onChange={handleInputChange(index, field, arrayIndex)}
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Story;
