import { useState } from "react";
import useOrderStore from '../../store/order-store'
import { useLocation } from "react-router-dom";

function ModularVersaSelection() {
  const { orderData,  setFormData } = useOrderStore();
  const selectedModularColor = orderData.leafFilterColor;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasSheet = queryParams.get('hasSheet');
  const isDisabled =(hasSheet === 'true');
  const handleInputChange = (value) => {
    if(isDisabled){
      return
    }
    setFormData({ 
      ...orderData,
      leafFilterColor: value
     });
  };
  return (
    <div className="k-d-grid k-grid-cols-5 k-grid-sm-cols-1 k-mt-3 k-pr-4">
      <h4 className="k-m-0 k-font-light k-pt-2">Modular & Versa:</h4>

      <label className="k-m-0 k-font-light k-pt-2">
        <input
        disabled={isDisabled}
        style={{marginRight:"10px"}}
          type="radio"
          name="modularColor"
          value="White"
          checked={selectedModularColor === "White"}
          onChange={() => handleInputChange("White")}
        />
        <span>White</span>
      </label>

      <label className="k-m-0 k-font-light k-pt-2">
        <input
        disabled={isDisabled}
        style={{marginRight:"10px"}}
          type="radio"
          name="modularColor"
          value="Clay"
          checked={selectedModularColor === "Clay"}
          onChange={() => handleInputChange("Clay")}
        />
        Clay
      </label>

      <label className="k-m-0 k-font-light k-pt-2">
        <input
        disabled={isDisabled}
        style={{marginRight:"10px"}}
          type="radio"
          name="modularColor"
          value="Bronze"
          checked={selectedModularColor === "Bronze"}
          onChange={() => handleInputChange("Bronze")}
        />
        Bronze
      </label>
    </div>
  );
}

export default ModularVersaSelection;
