export const HOST_API = 'https://leaffilter-api.revdmp.com';
export const SALES_FORCE_LOGIN_URL='https://login.salesforce.com/services/oauth2/token'
export const SALES_FORCE_GUTTER_URL='https://abc742-dev-ed.develop.my.salesforce.com/services/apexrest/api/GuttersAPI'
export const apiKey='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'
export const Gutter_sheet_prompt = `
<ROLE>
You are OCR expert and can extract all the data in the document and return in JSON
</ROLE>
<JSON_SCHEMA>
\`\`\`
{
  "$schema": "http://json-schema.org/draft-07/schema#",
  "title": "GutterFasciaSoffitPrepForm",
  "description": "Schema for Gutter, Fascia, and Soffit Prep Form, including measurements, roof details, fascia/soffit replacements, and additional fields with circle options.",
  "type": "object",
  "properties": {
    "CustomerDetails": {
      "type": "object",
      "properties": {
        "Customer": { "type": "string" },
        "City": { "type": "string" },
        "State": { "type": "string" },
        "JobNumber": { "type": "string" }
      },
      "required": ["Customer", "City", "State", "JobNumber"]
    },
    "Measurements": {
      "type": "object",
      "description": "Measurements for each part of the house at different heights.",
      "properties": {
        "Columns": {
          "type": "object",
          "properties": {
            "0To12ft": { "$ref": "#/definitions/MeasurementRow" },
            "12To24ft": { "$ref": "#/definitions/MeasurementRow" },
            "Over24ft": { "$ref": "#/definitions/MeasurementRow" }
          }
        }
      }
    },
    "RoofAndGutterDetails": {
      "type": "object",
      "properties": {
        "RoofType": { "type": ["string", "null"], "description": "Roof type specified by the user." },
        "GutterType": {
          "type": ["string", "null"],
          "enum": ["FS", "K-Style", "HR"],
          "description": "Gutter type selected by the user."
        },
        "GutterSize": {
          "type": ["string", "null"],
          "enum": ["4", "4.5", "6", "7", "Other"],
          "description": "Selected gutter size."
        },
        "TotalGutterFootage": { "type": ["number", "null"], "description": "Total gutter footage." },
        "DownspoutDetails": {
          "type": "object",
          "properties": {
            "DownspoutSize": {
              "type": ["string", "null"],
              "enum": ["2x3", "3x4", "3x3", "Round 4"],
              "description": "Size of the downspout."
            },
            "DownspoutColor": { "type": ["string", "null"], "description": "Color of the downspout." },
            "DownspoutFootage": { "type": ["number", "null"], "description": "Total downspout footage." }
          }
        },
        "Elbows": {
          "type": "object",
          "properties": {
            "NumberAElbows": { "type": ["number", "null"] },
            "NumberBElbows": { "type": ["number", "null"] },
            "NumberRoundElbows": { "type": ["number", "null"] },
            "NumberOffsetElbows": { "type": ["number", "null"] }
          }
        },
        "TotalFootage": { "type": ["number", "null"], "description": "Total footage (gutter + downspout + elbows)." },
        "PartialInstall": { "type": ["string", "null"], "enum": ["Yes", "No"] },
        "Miters": {
          "type": "object",
          "properties": {
            "Outside": { "type": ["number", "null"], "description": "Number of outside miters." },
            "Inside": { "type": ["number", "null"], "description": "Number of inside miters." }
          }
        },
        "EndCaps": {
          "type": "object",
          "properties": {
            "Rights": { "type": ["number", "null"], "description": "Number of right end caps." },
            "Lefts": { "type": ["number", "null"], "description": "Number of left end caps." }
          }
        },
        "AdditionalParts": {
          "type": "object",
          "properties": {
            "SpoutSavers": { "type": ["number", "null"], "description": "Number of spout savers." },
            "RoofStraps": { "type": ["number", "null"], "description": "Number of roof straps." },
            "Wedges": { "type": ["number", "null"], "description": "Number of wedges." }
          }
        }
      }
    },
    "FasciaReplacement": {
      "type": "object",
      "properties": {
        "ReplacementType": { "type": ["string", "null"], "enum": ["Full", "Partial"] },
        "TotalFootage": {
          "type": "object",
          "properties": {
            "CircleOption": { "type": "string", "enum": ["1x4", "1x6","1x8", "1x10"] },
            "Other": { "type": "string" }
          }
        },
        "Type": { "type": ["string", "null"], "description": "Material type (e.g., pine, cedar)." },
        "wrapType": {
          "type": "object",
          "properties": {
            "CircleOption": { "type": "string", "enum": ["Painted", "Metal wrapped"] },
            "Other": { "type": "string" }
          }
        },
        "NewWrapFootage": { "type": ["number", "null"], "description": "Footage of new wrap." }
      }
    },
    "SoffitReplacement": {
      "type": "object",
      "properties": {
        "ReplacementType": { "type": ["string", "null"], "enum": ["Full", "Partial"] },
        "TotalFootage": { "type": ["number", "null"] },
        "Material": {
          "type": "object",
          "properties": {
            "CircleOption": { "type": "string", "enum": ["Wood", "Vinyl"] },
            "Other": { "type": "string" }
          }
        },
        "Color": { "type": ["string", "null"], "description": "Color of the soffit." }
      }
    },
    "PorchCeiling": {
      "type": "array",
      "items": {
        "type": "object",
        "properties": {
          "Location": { "type": "string", "description": "Location of the porch ceiling." },
          "Length": { "type": "number", "description": "Length in feet." },
          "Width": { "type": "number", "description": "Width in feet." },
          "SquareFootage": { "type": "number", "description": "Total square footage." }
        }
      }
    },
    "DrawingAndNotes": {
      "type": ["string", "null"],
      "description": "Freeform area for additional drawings and notes."
    }
  }
}
\`\`\`
</JSON_SCHEMA>
# Return JSON document with data. Only return JSON not other text
# just output the extracted information in json format using JSON_SCHEMA.
`;
